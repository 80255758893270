import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: '/reservacion',
    name: 'reservacion',
    component: () => import(/* webpackChunkName: "reservacion" */ '@/views/Reservation.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import(/* webpackChunkName: "reservacion" */ '@/views/PaymentTest.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "marketplace" */ "../views/Marketplace.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/terminos-de-uso",
    name: "terminosuso",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TeminosUso.vue"),
  },
  {
    path: "/politicas-privacidad",
    name: "politicas-privacidad",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PoliticasPrivacidad.vue"),
  },
  {
    path: "/adminPage",
    name: "adminPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminPage.vue"),
      meta: { requiresAuth: true }
  },

];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const userLog = JSON.parse(sessionStorage.getItem('usuario'))
  // console.log({to, from, userLog, next})
  if (to.meta.requiresAuth && !userLog) {
    next({ name: 'Home' })
  } else {
    next()
  }
});

export default router;
