import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "@/assets/theme/fonts.css";
import store from "./store";
import Vuesax from "vuesax";

import "vuesax/dist/vuesax.css"; //Vuesax styles
Vue.use(Vuesax, {
  // options here
});

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBRLO3qmSnwPqLLoKm5JPBmCuUkux50ofo",
  authDomain: "pedreragarden.firebaseapp.com",
  projectId: "pedreragarden",
  storageBucket: "pedreragarden.appspot.com",
  messagingSenderId: "501344693505",
  appId: "1:501344693505:web:720845002f5592a25bff00",
  measurementId: "G-7EFB8ZGL9S",
};

// Initialize Firebase
initializeApp(firebaseConfig);

import { getAuth, onAuthStateChanged } from "firebase/auth";
//const db = getFirestore();
const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (user) {
    const usuario = JSON.parse(sessionStorage.getItem("usuario"));
    //console.log("THE USER: ", usuario);
    store.commit("auth/setUsuario", usuario);
  } else {
    console.log("invalid user");
  }
});

Vue.config.productionTip = false;

// For DatePicker ---

// import component and stylesheet
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import "vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css";

import VueCarousel from "vue-carousel";

import DatetimePicker from "vuetify-datetime-picker";

// see docs for available options
const datepickerOptions = {
  dateLabelFormat: "dddd, MMMM D, YYYY",
  days: [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
  ],
  daysShort: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
};

// make sure we can use it in our components
Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.use(VueCarousel);
Vue.use(DatetimePicker);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
